<template>
  <table style="height: 100%; width: 100%;">
    <tbody>
      <tr style="vertical-align: middle;">
        <td style="text-align: center;">
          <table style="margin: auto;">
            <tbody>
              <tr style="vertical-align: bottom;">
                <td style="text-align: center;">
                  <a href="https://cms.pointsmap.com/">
                    <img src="https://pointsmap.com/images/logo_v3R.png" width="240" height="80"
                         alt="PointsMap hits the spot. Powered by Video Ideas technology."/>
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="text-align: center;">
                  <p>
                    This PointsMap is currently turned off.<br />
                    Please check back later.
                  </p>
                  <p>
                    Not sure what PointsMap is?<br />
                    <a href="https://cms.pointsmap.com/">Click here to learn more</a>.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr style="vertical-align: bottom;">
        <td>
          <table style="width: 100%;">
            <tbody>
              <tr style="vertical-align: middle;">
                <td class="footerText" style="text-align: left;">
                  Copyright &copy; 2022 by <a href="https://www.videoideas.com/" target="_blank">Video Ideas Productions, Inc.</a><br />
                  ALL RIGHTS RESERVED.
                </td>
                <td class="footerText" style="text-align: right;"><a href="https://www.videoideas.com/" target="_blank" title="Video Ideas">
                  <img src="https://pointsmap.com/images/viplogo_16x16.png" alt="Video Ideas" />
                </a></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "AccountDisabledMessage",
})
</script>

<style scoped>
.footerText {
  color: #999999;
  font-size: small;
  padding: 10px;
}

.footerText > img {
  border: 0;
}

img {
  background-color: #fff;
  box-shadow: 0 0 4px 4px #fff;
}
</style>
