<template>
  <ion-list id="folder-list">
    <div class="exit-submap">
      <ion-menu-toggle v-if="insideSubmap" auto-hide="false">
        <ion-item lines="full" detail="false" class="hydrated" @click="exitSubmap">
          <ion-icon class="exit-submap-icon" src="assets/icon/exit.svg" />
          <ion-label style="margin-left: 1ex;">Go outside</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </div>
    <div class="favorites">
      <ion-item lines="none" detail="false" class="hydrated"
                @click="showFavorites = !showFavorites"
                :class="{ selected: showFavorites }">
        <ion-icon src="assets/icon/star.svg" class="favorites-star" />
        <ion-label style="margin-left: 1ex;">Favorites</ion-label>
      </ion-item>
      <ion-menu-toggle v-if="showFavorites" auto-hide="false">
        <ion-item
            style="padding-left: 1ex"
            v-for="(p, k) in favoritePoints" :key="k"
            router-direction="root"
            :router-link="selectedPoint === p.guid ? getUrlPathToDeselectPoint(p) : getUrlPathForPoint(p)"
            lines="none" detail="false" class="hydrated"
            :class="{ selected: selectedPoint === p.guid }">
          <ion-label style="margin: 0;">
            <div :style="{ backgroundImage: p.photos && p.photos[0] ? `url('https://pointsmap.com/${accountCodeName}/data/photos/${encodeURIComponent(p.photos[0])}')` : 'none' }"
                 class="point-label-bg" v-bind:class="{'logo-image': p.primaryPhotoIsLogo}">
              <div class="point-label">{{ p.label }}</div>
            </div>
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
    </div>
    <div v-for="(folder, i) in folders" :key="i">
      <MenuFolder
          :account-code-name="accountCodeName"
          :folder="folder"
          :selected-folder="selectedFolder"
          :selected-point="selectedPoint"
          :selected-submap="selectedSubmap"
      />
    </div>
  </ion-list>
</template>

<script>
import MenuFolder from "@/components/MenuFolder";
import store from "@/store";
import { favoritePointGuids } from '@/store/favorites'
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
} from "@ionic/vue";
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

const pointsByGuid = ref([])
const showFavorites = ref(false)

export default defineComponent({
  name: "FolderList",
  components: {
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenuToggle,
    MenuFolder,
  },
  computed: {
    favoritePoints() {
      const pointGuids = Object.keys(favoritePointGuids.value)

      // Note: Filter out Point GUIDs with no matching point. They probably
      // belong to other PointsMaps.
      return pointGuids.map(pointGuid => pointsByGuid.value[pointGuid]).filter(point => point)
    },
    insideSubmap() {
      return !!store.state.selectedSubmap
    },
  },
  props: {
    accountCodeName: String,
    folders: Array,
    selectedPoint: String,
    selectedFolder: String,
    selectedSubmap: String,
  },
  setup(props) {
    store.getPointsByGuidOnceLoaded().then(data => {
      pointsByGuid.value = data
    })

    const router = useRouter();
    const exitSubmap = () => {
      const submapGuid = store.state.selectedSubmap
      const submap = store.getSubmap(submapGuid)
      if (submap) {
        const pointGuid = submap.parentPointGuid
        router.push({ name: 'point', params: { pointGuid } })
      }
    }

    const getUrlPathForPoint = (point) => {
      if (point.parentSubmap) {
        return `/${props.accountCodeName}/submap/${point.parentSubmap}/point/${point.guid}`
      } else {
        return `/${props.accountCodeName}/point/${point.guid}`
      }
    }

    const getUrlPathToDeselectPoint = (point) => {
      if (point.parentSubmap) {
        return `/${props.accountCodeName}/submap/${point.parentSubmap}`
      } else {
        return `/${props.accountCodeName}`
      }
    }

    return {
      exitSubmap,
      getUrlPathForPoint,
      getUrlPathToDeselectPoint,
      showFavorites,
    }
  },
})
</script>

<style scoped>
.exit-submap-icon {
  color: var(--color);
  transform: rotate(180deg); /* because we want the arrow to point left */
  width: 40px;
  height: 36px;
}

.favorites-star {
  color: var(--ion-color-primary);
  width: 40px;
  height: 36px;
}

ion-list.md {
  padding: 20px 0;
}

ion-list-header.md {
  padding-left: 10px;
}

ion-list.md#folder-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-list.md#folder-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-list.md#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-item.md {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-item.md.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-item.md ion-label {
  font-weight: 500;
}

ion-list.ios {
  padding: 20px 0 0 0;
}

ion-note.ios {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-item.ios {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-list.ios#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-list.ios-header {
  padding-left: 16px;
  padding-right: 16px;
}

ion-note.ios {
  margin-bottom: 8px;
}

ion-item {
  cursor: pointer;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.point-label {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  white-space: initial;
}

.point-label-bg {
  position: relative;
  min-height: 90px;
  margin: 3px 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.point-label-bg.logo-image {
  background-color: #fff;
  background-size: contain;
}
</style>
