import { ref } from 'vue'

export const favoritePointGuids = ref<any>({})

export const isFavoritePoint = (pointGuid: string): boolean => {
   return favoritePointGuids.value[pointGuid] || false
}

export const loadFavorites = () => {
   const pointGuidsString = localStorage.getItem('favoritePoints') || ''
   const pointGuids = pointGuidsString.split(',')
   pointGuids.forEach(pointGuid => {
      favoritePointGuids.value[pointGuid] = true
   })
}

const saveFavorites = () => {
   const favoritePoints = Object.keys(favoritePointGuids.value)
   if (favoritePoints[favoritePoints.length - 1] === '') {
      favoritePoints.pop()
   }
   localStorage.setItem(
     'favoritePoints',
     favoritePoints.join(',')
   )
}

export const toggleFavorite = (pointGuid: string): boolean => {
   if (favoritePointGuids.value[pointGuid]) {
      delete favoritePointGuids.value[pointGuid]
   } else {
      favoritePointGuids.value[pointGuid] = true
   }
   saveFavorites()
   return favoritePointGuids.value[pointGuid] || false
}
