
export const assembleRouterLinkFrom = (details: any) => {
  let routerLink = `/${details.accountCodeName}`
  
  if (details.selectedSubmap) {
    routerLink += `/submap/${details.selectedSubmap}`
  } else if (details.submap) {
    routerLink += `/submap/${details.submap.guid}`
  }
  
  if (details.selectedFolder === details.folder?.guid) {
    if (details.folder?.parentFolderId) {
      routerLink += `/folder/${details.folder.parentFolderId}`
    }
  } else {
    routerLink += `/folder/${details.folder.guid}`
  }
  
  if (details.selectedPoint !== details.point?.guid) {
    routerLink += `/point/${details.point.guid}`
  }
  
  return routerLink
}
