import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FolderList = _resolveComponent("FolderList")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_IonSplitPane = _resolveComponent("IonSplitPane")!
  const _component_account_disabled_message = _resolveComponent("account-disabled-message")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, null, {
    default: _withCtx(() => [
      (_ctx.enabled)
        ? (_openBlock(), _createBlock(_component_IonSplitPane, {
            key: 0,
            "content-id": "main-content"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu, {
                "content-id": "main-content",
                type: "overlay"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_FolderList, {
                        "account-code-name": _ctx.accountCodeName,
                        folders: _ctx.foldersOnCurrentMap,
                        "selected-folder": _ctx.store.state.selectedFolder,
                        "selected-point": _ctx.store.state.selectedPoint,
                        "selected-submap": _ctx.store.state.selectedSubmap
                      }, null, 8, ["account-code-name", "folders", "selected-folder", "selected-point", "selected-submap"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_router_outlet, { id: "main-content" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.enabled)
        ? (_openBlock(), _createBlock(_component_account_disabled_message, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}