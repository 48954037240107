<template>
  <ion-item
      :id="`menu-folder-${folder.guid}`"
      router-direction="root"
      :router-link="assembleRouterLinkFrom({folder, selectedFolder, accountCodeName, selectedSubmap})"
      lines="none" detail="false" class="hydrated"
      :class="{ selected: selectedFolder === folder.guid }">
    <ion-img :src="`https://pointsmap.com/images/icons/points/${folder.icon}`" />
    <ion-label style="margin-left: 1ex">{{ folder.label }}</ion-label>
  </ion-item>
  <div v-if="isOrContainsSelectedFolder(folder, selectedFolder)" style="padding-left: 1ex">
    <div v-for="(subfolder, j) in folder.folders" :key="j">
      <MenuFolder
          :account-code-name="accountCodeName"
          :folder="subfolder"
          :selected-folder="selectedFolder"
          :selected-point="selectedPoint"
          :selected-submap="selectedSubmap"
      />
    </div>
  </div>
  <ion-menu-toggle
      v-if="isOrContainsSelectedFolder(folder, selectedFolder)"
      auto-hide="false">
    <ion-item
        style="padding-left: 1ex"
        v-for="(point, k) in folder.pointGuids.map(guid => pointsByGuid[guid] || {})" :key="k"
        router-direction="root"
        :router-link="assembleRouterLinkFrom({folder, point, selectedPoint, accountCodeName, selectedSubmap})"
        lines="none" detail="false" class="hydrated"
        :class="{ selected: selectedPoint === point.guid }">
      <ion-label style="margin: 0;">
        <div :style="{ backgroundImage: point.photos && point.photos[0] ? `url('https://pointsmap.com/${accountCodeName}/data/photos/${point.photos[0]}')` : 'none' }"
             class="point-label-bg" v-bind:class="{'logo-image': point.primaryPhotoIsLogo}">
          <div class="point-label">{{ point.label }}</div>
        </div>
      </ion-label>
    </ion-item>
  </ion-menu-toggle>
</template>

<script>
import { assembleRouterLinkFrom } from '@/helpers/links'
import store from '@/store'
import {
  IonImg,
  IonItem,
  IonLabel,
  IonMenuToggle,
} from "@ionic/vue";
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from "vue-router";

const pointsByGuid = ref({})

export default defineComponent({
  name: "MenuFolder",
  components: {
    IonItem,
    IonImg,
    IonLabel,
    IonMenuToggle,
  },
  props: {
    accountCodeName: String,
    folder: Object,
    selectedFolder: String,
    selectedPoint: String,
    selectedSubmap: String,
  },
  setup(props) {
    store.getPointsByGuidOnceLoaded().then(data => {
      pointsByGuid.value = data
    })

    const route = useRoute()
    watch(
      () => route.params.folderGuid,
      () => {
        if (route.params.folderGuid) {
          if (route.params.folderGuid === props.folder.guid) {
            const element = document.getElementById(`menu-folder-${props.folder.guid}`)
            if (element) {
              setTimeout(() => {
                element.scrollIntoView({behavior: "smooth"})
              })
            }
          }
        }
      }
    )

    return {
      assembleRouterLinkFrom,
      isOrContainsSelectedFolder: store.isOrContainsSelectedFolder,
      pointsByGuid,
    }
  },
})
</script>

<style scoped>
ion-item.md {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-item.md.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-item.md.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-item.md ion-icon {
  color: #616e7e;
}

ion-item.md ion-label {
  font-weight: 500;
}

ion-note.ios {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-item.ios {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-item.ios.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-item.ios ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-item {
  cursor: pointer;
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.point-label {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  white-space: initial;
}

.point-label-bg {
  position: relative;
  min-height: 90px;
  margin: 3px 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.point-label-bg.logo-image {
  background-color: #fff;
  background-size: contain;
}
</style>
