import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/demo'
  },
  {
    path: '/:accountCodeName',
    name: 'home',
    component: () => import ('../views/Home.vue'),
    props: route => ({ queryStringPointGuid: route.query.pid }),
    children: [
      {
        path: 'point/:pointGuid',
        name: 'point',
        component: () => import ('@/components/PointDetails.vue'),
      },
      {
        path: 'folder/:folderGuid/point/:pointGuid',
        name: 'point-in-folder',
        component: () => import ('@/components/PointDetails.vue'),
      },
      {
        path: 'folder/:folderGuid',
        name: 'folder',
        component: () => import ('@/components/FolderDetails.vue'),
      },
      {
        path: 'search/:searchString',
        name: 'search',
        component: () => import ('@/components/SearchResults.vue'),
      },
      {
        path: 'submap/:submapGuid',
        name: 'submap',
        component: () => import ('@/components/Submap.vue'),
        children: [
          {
            path: 'folder/:folderGuid',
            name: 'folder-in-submap',
            component: () => import ('@/components/FolderDetails.vue'),
          },
          {
            path: 'point/:pointGuid',
            name: 'point-in-submap',
            component: () => import ('@/components/PointDetails.vue'),
          },
          {
            path: 'folder/:folderGuid/point/:pointGuid',
            name: 'point-in-folder-in-submap',
            component: () => import ('@/components/PointDetails.vue'),
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
