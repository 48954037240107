import { xml2js } from 'xml-js';
import { asArray} from "@/helpers/as-array";

const restructureGroupData = (groupData: any): any => {
   const meta = groupData._attributes
   return {
      guid: meta.guid,
      icon: groupData.icon._text,
      id: meta.id,
      label: meta.label,
      lat: +groupData.lat._text, // Cast to an actual number.
      lng: +groupData.lng._text, // Cast to an actual number.
      listButtonTitle: meta.listButtonTitle,
      masterPointGuid: meta.masterPoint,
      xPct: groupData.xPct?._text ? +groupData.xPct?._text : undefined, // Cast to an actual number.
      yPct: groupData.yPct?._text ? +groupData.yPct?._text : undefined, // Cast to an actual number.
   }
}

const getGroupsByPointGuidFromXml = (xml: string) => {
   const xmlData = xml2js(xml, {
      compact: true,
   }) as any
   const groupListElement = xmlData.groupList || {}
   const groupsData = asArray(groupListElement.group)
   const groupsByPointGuid: any = {}
   groupsData.forEach((groupData: any) => {
      const group = restructureGroupData(groupData)
      groupsByPointGuid[group.masterPointGuid] = group
   })
   return groupsByPointGuid
}

const loadGroups = async (accountCodeName: string): Promise<any> => {
   const url = `https://pointsmap.com/${accountCodeName}/data/groupList.xml`
   const response = await fetch(url)
   const groupsXml = await response.text()
   return getGroupsByPointGuidFromXml(groupsXml)
}

export default loadGroups
