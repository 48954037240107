import { xml2js } from 'xml-js';
import { asArray} from "@/helpers/as-array";

const restructureSubmapData = (submapData: any): any => {
   const meta = submapData._attributes
   return {
      defaultWaylineColor: '#' + meta.defaultWaylineColor,
      guid: meta.guid,
      hasWayfinding: (meta.hasWayfinding === 'true'),
      highlightedWaylineColor: '#' + meta.highlightedWaylineColor,
      id: meta.id,
      image: meta.image,
      label: meta.label,
      parentPointGuid: meta.parentPoint,
      sortId: +meta.sort_id, // Cast to an actual number.
      waylineWeight: +meta.waylineWeight, // Cast to an actual number.
   }
}

const getSubmapsByPointGuidFromXml = (xml: string) => {
   const xmlData = xml2js(xml, {
      compact: true,
   }) as any
   const submapListElement = xmlData.submapList || {}
   const submapsData = asArray(submapListElement.submap)
   const submapsByPointGuid: any = {}
   submapsData.forEach((submapData: any) => {
      const submap = restructureSubmapData(submapData)
      const submapsForPoint = submapsByPointGuid[submap.parentPointGuid] || []
      submapsForPoint.push(submap)
      submapsByPointGuid[submap.parentPointGuid] = submapsForPoint
   })
   return submapsByPointGuid
}

const loadSubmaps = async (accountCodeName: string): Promise<any> => {
   const url = `https://pointsmap.com/${accountCodeName}/data/submapList.xml`
   const response = await fetch(url)
   const submapsXml = await response.text()
   return getSubmapsByPointGuidFromXml(submapsXml)
}

export default loadSubmaps
