import { xml2js } from 'xml-js';
import store from "@/store/index";
import { asArray} from "@/helpers/as-array";

const restructureLatLngLineData = (latLngLineData: any): any => {
   if (latLngLineData === undefined) {
      return undefined
   }
   return {
      path: String(latLngLineData._cdata).trimEnd().split(' ').map(latLngString => {
         const latAndLng = latLngString.split(',').map(num => +num)
         return {
            lat: latAndLng[0],
            lng: latAndLng[1],
         }
      }),
      strokeColor: '#' + latLngLineData._attributes.strokeColor,
      strokeOpacity: +latLngLineData._attributes.strokeOpacity,
      strokeWeight: +latLngLineData._attributes.strokeThickness,
   }
}

const restructureLatLngZoneData = (latLngZoneData: any): any => {
   if (latLngZoneData === undefined) {
      return undefined
   }
   return {
      fillColor: '#' + latLngZoneData._attributes.fillColor,
      fillOpacity: +latLngZoneData._attributes.fillOpacity,
      path: String(latLngZoneData._cdata).trimEnd().split(' ').map(latLngString => {
         const latAndLng = latLngString.split(',').map(num => +num)
         return {
            lat: latAndLng[0],
            lng: latAndLng[1],
         }
      }),
      strokeColor: '#' + latLngZoneData._attributes.strokeColor,
      strokeOpacity: +latLngZoneData._attributes.strokeOpacity,
      strokeWeight: +latLngZoneData._attributes.strokeThickness,
   }
}

const restructureMenuData = (menu: any): Array<any> => {
   const buttons = asArray(menu?.button)
   return buttons.map((button: any) => ({
      label: button.label?._cdata,
      type: button.type?._text,
      value: button.value?._cdata,
   }))
}

const restructurePointData = (pointData: any): any => {
   const meta = pointData._attributes
   return {
      details: {
         content: pointData.details._cdata,
         html: pointData.details._attributes.html,
      },
      folder: pointData.folder._text,
      group: pointData.group?._text,
      guid: meta.guid,
      icon: +meta.usePointIcon ? meta.pointIcon : pointData.icon?._text,
      id: meta.id,
      isLowPriority: meta.isLowPriority,
      label: meta.label,
      lat: +pointData.lat._text, // Cast to an actual number.
      lng: +pointData.lng._text, // Cast to an actual number.
      xPct: +pointData.xPct?._text, // Cast to an actual number.
      yPct: +pointData.yPct?._text, // Cast to an actual number.
      latLngLine: restructureLatLngLineData(pointData.latLngLine),
      latLngZone: restructureLatLngZoneData(pointData.latLngZone),
      menu: restructureMenuData(pointData.menu),
      noSearch: !!pointData.noSearch,
      parentSubmap: pointData.parentSubmap?._text,
      photos: asArray(pointData.photos.photo).map(p => p._text),
      pointIcon: meta.pointIcon,
      primaryPhotoIsLogo: !!+meta.primaryPhotoIsLogo, // "0"/"1" to false/true
      secondaryFolders: meta?.secondaryFolders?.split(',') || [],
      subtitle: {
         bottom: pointData.subtitle.bottom._text,
         top: pointData.subtitle.top._text,
      },
      usePointIcon: meta.usePointIcon,
      virtualPhotos: asArray(pointData.virtualphotos.photo).map(p => p._text),
      website: pointData.website?._cdata,
   }
}

const getPointsByGuidFromXml = (xml: string) => {
   const xmlData = xml2js(xml, {
      compact: true,
   }) as any
   const pointListElement = xmlData.poiList || {}
   const pointsData = asArray(pointListElement.poi)
   const pointsByGuid: any = {}
   pointsData.forEach((pointData: any) => {
      const point = restructurePointData(pointData)
      pointsByGuid[point.guid] = point
   })
   return pointsByGuid
}

export const inSelectedFolder = (point: any) => {
   if (store.isSelectedFolder(point.folder)) {
      return true
   }
   return point.secondaryFolders.some(store.isSelectedFolder)
}

export const isInAGroup = (point: any): boolean => !!point.group

export const isPointSelected = (point: any) => {
   if (store.state.selectedPoint !== undefined) {
      if (store.isSelectedPoint(point.guid)) {
         return true
      } else {
         const group = store.getGroup(point.guid)
         if (group) {
            const pointsInGroup = Object.values(store.state.pointsByGuid).filter((p: any) => p.group === group.guid)
            return pointsInGroup.some((p: any) => store.isSelectedPoint(p.guid))
         }
      }
      return false
   }
   return inSelectedFolder(point)
}

const loadPoints = async (accountCodeName: string): Promise<any> => {
   const url = `https://pointsmap.com/${accountCodeName}/data/poiList.xml`
   const response = await fetch(url)
   const pointsXml = await response.text()
   return getPointsByGuidFromXml(pointsXml)
}

export default loadPoints
