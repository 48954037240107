
export const reportPointHit = async (accountCodeName: string, pointGuid: string, submapGuid: string|null) => {
  const data: any = {
    'p_id': pointGuid,
    'act_id': accountCodeName,
    'user_agent': navigator.userAgent,
    'client_platform': 'app',
  }
  if (submapGuid) {
    data['submap_id'] = submapGuid
  }
  
  const response = await fetch(
    'https://pointsmap.com/__point_view.php',
    {
      method: 'POST',
      body: new URLSearchParams(data)
    }
  )
  
  if (! response.ok) {
    console.debug('Failed to record point hit')
  }
}
