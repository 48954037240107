import { xml2js } from 'xml-js';
import { asArray} from "@/helpers/as-array";

const iconsByGuid: any = {}

const restructureFolderData = (folderData: any): any => {
   const meta = folderData._attributes
   const subfolders = asArray(folderData.folder)
   const points = asArray(folderData.point)
   iconsByGuid[meta.guid] = meta.icon
   return {
      folders: subfolders.map(restructureFolderData),
      guid: meta.guid,
      icon: meta.icon,
      id: meta.id,
      initHid: meta.initHid,
      label: meta.label,
      labelCategory: meta.label_category,
      parentFolderId: meta.parentFolderId,
      pointGuids: points.map((point: any) => point._attributes.guid),
      privateCategory: meta.private_category,
      sortId: meta.sort_id,
      submapId: meta.submap,
   }
}

const getFoldersFromXml = (xml: string) => {
   const xmlData = xml2js(xml, {
      compact: true,
   }) as any
   const folderListElement = xmlData.folderList || {}
   const folders = asArray(folderListElement.folder)
   return folders.map(restructureFolderData)
}

export const getIconForFolder = (folderGuid: any) => iconsByGuid[folderGuid]

const loadFolders = async (accountCodeName: string): Promise<any> => {
   const url = `https://pointsmap.com/${accountCodeName}/data/folderList.xml`
   const response = await fetch(url)
   const foldersXml = await response.text()
   return getFoldersFromXml(foldersXml)
}

export default loadFolders
