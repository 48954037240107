
import AccountDisabledMessage from '@/components/AccountDisabledMessage.vue'
import FolderList from '@/components/FolderList.vue'
import store from '@/store'
import { loadFavorites } from '@/store/favorites'
import {
  IonApp,
  IonContent,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
} from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const accountCodeName = ref<any>('')
const folders = ref<any>([])
const selectedFolderId = ref<any>(undefined)

const onCurrentSubmap = (folder: any) => folder.submapId === store.state.selectedSubmap

export default defineComponent({
  name: 'App',
  components: {
    AccountDisabledMessage,
    FolderList,
    IonApp, 
    IonContent, 
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
  },
  computed: {
    enabled(): boolean {
      return store.state.enabled
    },
    foldersOnCurrentMap() {
      return folders.value.filter(onCurrentSubmap)
    },
  },
  data() {
    return {
      store,
    }
  },
  setup() {
    const route = useRoute();
    accountCodeName.value = String(route.params.accountCodeName)

    store.loadAppData(accountCodeName.value).then(() => {
      folders.value = store.state.folders
      store.selectFolder(route.params.folderGuid)
      store.selectPoint(route.params.pointGuid)
      store.selectSubmap(route.params.submapGuid)
    })

    loadFavorites()

    watch(
      () => route.params.folderGuid,
      () => {
        store.selectFolder(route.params.folderGuid)
        selectedFolderId.value = route.params.folderGuid
      }
    )

    watch(
      () => route.params.pointGuid,
      () => {
        store.selectPoint(route.params.pointGuid)
      }
    )

    watch(
      () => route.params.submapGuid,
      () => {
        store.selectSubmap(route.params.submapGuid)
      }
    )

    document.addEventListener('visibilitychange', (store as any).onVisibilityChange)
    window.addEventListener('focus', (store as any).onWindowFocus)
    document.addEventListener('focus', (store as any).onDocumentFocus)

    return {
      accountCodeName,
      selectFolder: store.selectFolder,
    }
  },
});
